.mat-mdc-row,
.mdc-data-table__content {
  font-size: var(--font-size-x-sm) !important;
}

.cdk-overlay-dark-backdrop {
  background: transparent !important;
}

.mdc-list-item__end {
  margin-left: 8px !important;
}

.mat-drawer-inner-container,
.mat-drawer {
  overflow: hidden !important;
}
.mat-button-toggle-label-content {
  line-height: 36px !important;
}

.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background-color: black !important;
}

.mat-button-toggle-appearance-standard {
  font-size: inherit !important;
}

.mat-button-toggle-group-appearance-standard {
  border: solid 1px black !important;
}
.mat-button-toggle-appearance-standard.mat-button-toggle-checked
  .mat-button-toggle-label-content {
  color: white !important;
}
.mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after {
  color: white !important;
}

.mat-step-label.mat-step-label-selected {
  font-weight: var(--font-weight-semibold) !important;
  font-size: var(--font-size-sm) !important;
}

.mat-datepicker-content {
  .mat-datepicker-actions button {
    box-shadow: none !important;
  }
}

.mat-tooltip {
  display: flex;
  position: relative;
  z-index: 1000000;
  margin-left: 20px;
  box-shadow: 0px 2px 8px 0px rgb(0 0 0 / 10%) !important;
  background-color: var(--primary) !important;
  font-size: 13px !important;
  color: var(--dark) !important;
  padding: var(--margin-sm) !important;
  font-weight: var(--font-weight-semibold);
}

.mat-mdc-form-field-error {
  color: var(--danger) !important;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--dark) !important;
}

.mat-drawer-content {
  overflow: hidden !important;
  display: flex !important;
}
