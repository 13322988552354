.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: var(--font-weight-normal);
  font-size: 14px;
  position: relative;
  transition: var(--transition-ease-in-out);
  padding: 0;
  line-height: unset;
  border: var(--border-radius-x-sm);
  min-width: 32px;
  min-height: 36px;
  height: var(--control-height);
  border-radius: var(--border-radius-x-sm);
  background: transparent;
  cursor: pointer;

  &[disabled] {
    opacity: 0.6;
  }

  &.btn-metadata,
  &.btn-primary,
  &.btn-dark,
  &.btn-outline-primary,
  &.btn-outline-dark {
    color: var(--dark);
    box-shadow: var(--box-shadow);
    padding: 0 var(--margin-md);

    &:hover,
    &:focus,
    &:active,
    .active {
      box-shadow: var(--box-shadow-bold);
      transition: var(--transition-ease-in-out);
    }
  }

  &.btn-primary {
    background: var(--primary);
  }

  &.btn-metadata {
    background: var(--metadata);
    color: var(--light) !important;
    &:focus,
    &:active {
      background: var(--metadata) !important;
      color: var(--light) !important;
    }
  }

  &.btn-dark {
    background: var(--dark);
    color: var(--light);
  }

  &.btn-outline-primary {
    background: var(--primary);
    border: 1px solid var(--color-border);
  }

  &.btn-outline-dark {
    background: var(--light);
    border: 1px solid var(--color-border);
    color: var(--dark);
    &:hover {
      background: var(--color-background);
    }
  }

  &.btn-sm {
    font-size: 10px;
    padding: var(--margin-xx-sm) var(--margin-xx-sm);
    max-height: 24px;
    min-height: 24px;
    min-width: 24px;
  }

  &.btn-lg {
    font-size: 14px;
    padding-left: var(margin-xx-lg);
    padding-right: var(margin-xx-lg);
    min-height: 40px;
    min-width: 40px;
  }

  &.btn-md {
    padding: var(--margin-xx-sm) var(--margin-xx-sm);
    min-height: 28px;
    min-width: 28px;
  }

  &.btn-link {
    border-radius: 0px;
    background: transparent;
    color: var(--dark);
    box-shadow: none;
    text-decoration: none;
    &:focus {
      outline: none;
    }
  }
  &.btn-link-secondary {
    border-radius: 0px;
    background: transparent;
    color: var(--secondary);
    box-shadow: none;
    text-decoration: underline;
    font-weight: var(--font-weight-semibold);
    &:focus {
      outline: none;
    }
    &:hover {
      text-decoration: none;
    }
  }

  &.btn-block {
    width: 100%;
  }

  &.btn-rounded {
    border-radius: var(--border-radius-sm);
  }

  &.btn-icon {
    padding: 0;

    &:focus {
      box-shadow: none !important;
    }
  }

  &.btn-round {
    border-radius: 100% !important;
    padding: 0;
    &.btn-round-sm {
      width: 32px;
      height: 32px;
      min-width: 32px;
    }

    &.btn-round-lg {
      font-size: 18px;
      width: 40px;
      height: 40px;
      min-width: 40px;
    }

    &.btn-round-xl {
      font-size: 20px;
      width: 45px;
      height: 45px;
      min-width: 45px;
    }
  }
}

.btn-group {
  display: flex;
  .btn {
    &.btn-round {
      margin-right: 4px;
      &:last-of-type {
        margin-right: 0px;
      }
    }
  }
}

.btn-group > .btn-group:not(:last-child) > .btn,
.btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn-group:not(:first-child) > .btn,
.btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group > .btn-group:not(:first-child),
.btn-group > .btn:not(:first-child) {
  margin-left: -1px;
}
