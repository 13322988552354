mat-list {
  height: 100%;
  overflow: auto;
  padding: 0 !important;
  .mat-list-item-content {
    font-size: var(--font-size-sm);
  }
}
.mdc-list {
  padding: 0 !important;
}
.mdc-list-item {
  border-radius: var(--border-radius-x-sm) !important;
}
