p.lead {
  font-size: 14px;
  padding-top: 5px;
  margin-bottom: 0px;
  padding-bottom: 10px;
}

h1 {
  margin-bottom: 0px;
  font-weight: var(--font-weight-bold);
  .category {
    display: none;
    font-weight: var(--font-weight-light);
    padding-right: 5px;
  }
}

.label-secondary {
  color: var(--secondary) !important;
  text-decoration: underline;
  font-weight: var(--font-weight-semibold);

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: var(--secondary) !important;
  }
}

.small-text {
  font-size: var(--font-size-sm);
  font-style: italic;
}

.info-label {
  color: var(--metadata);
  font-size: var(--font-size-md);
}

.d-flex-end {
  display: flex;
  justify-content: flex-end;
}

.title {
  font-size: 30px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: var(--font-weight-semibold);
  font-family: var(--font-family-mont);
}

.fill {
  background: var(--color-background);
  padding: var(--margin-md);
  border-radius: var(--border-radius-x-sm);
}

quill-editor {
  background-color: var(--light);
  border-radius: var(--border-radius-x-sm);
  &:focus-within {
    border-color: #90f396;
    outline: 0;
    box-shadow: 0 0 0 0.2rem #1de62a40;
    display: flex;
    flex-direction: column;
    margin: 0.2rem;
  }
  .ql-editor {
    font-size: var(--font-size-x-sm);
    font-family: var(--font-family-mont);
  }
  .ql-container,
  .ql-toolbar {
    border: 1px solid var(--color-border) !important;
    &:focus-within {
      border: 0 !important;
    }
  }

  .ql-container {
    border-top: 0 !important;
    width: 100% !important;
    .ql-editing {
      left: 0 !important;
      &::before {
        display: none !important;
      }
      input {
        width: auto !important;
      }
    }
  }
}

.highlight {
  background-color: var(--primary);
  font-weight: bold;
}

.drag-placeholder {
  background: #ccc;
  border: solid 1px var(--color-border);
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.quill__container {
  display: flex;
  flex-direction: column;
  span {
    font-weight: var(--font-weight-bold);
  }
}

.required::after {
  content: '*';
}

.editor__container {
  width: 300px;
}

.tooltip_container {
  display: flex;
  flex-direction: column;
}

.unread__container {
  position: relative;
  display: flex;
  .unread__count {
    position: absolute;
    right: -19px;
    top: -5px;
    border-radius: 100%;
    background: var(--secondary);
    font-size: 10px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    font-weight: var(--font-weight-semibold);
  }
  .unread__title {
    z-index: 100;
  }
}

.subtitle {
  color: var(--metadata);
  font-size: var(--font-size-lg);
  font-weight: normal;
  line-height: unset;
  font-family: var(--font-family-mont);
}

.mdc-list-item__primary-text {
  font-size: var(--font-size-sm) !important;
}

ol {
  list-style: unset !important;
  margin: inherit !important;
  padding: revert !important;
}

.menu__container {
  .menu__sidebar {
    position: absolute;
    left: 210px;
    bottom: 0;
    background: var(--light);
    border-left: 1px solid var(--color-border);
    padding: var(--margin-x-sm);
    box-shadow: var(--box-shadow-bold);
    border-radius: var(--border-radius-x-sm);
    itq-dropdown-results {
      width: 250px;
    }
  }
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;

  /* For WebKit browsers */
  &::-webkit-scrollbar {
    height: 12px; /* Height of the horizontal scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track */
  }

  &::-webkit-scrollbar-thumb {
    background: #888; /* Color of the thumb */
    border-radius: 6px; /* Rounded corners */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the thumb on hover */
  }
}
